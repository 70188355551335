<template>
  <div class="container2">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-select v-model="service" placeholder="請選擇平台" style="width: 100%;">
          <el-option v-for="item in services"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-input v-model="auth" placeholder="輸入 ID"></el-input>
      </el-col>
    </el-row><br style="margin-top: 10px;"/>
    <el-table :data="tableData"
      height="calc(100vh - 170px)"
      border
      style="width: 100%">
      <el-table-column
        prop="Phone"
        label="電話">
      </el-table-column>
      <el-table-column
        prop="remark"
        label="備註">
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px; text-align: right;">
      <el-button type="warning" @click="export2Excel">下載範例檔</el-button>
      <label style="padding: 10px 20px; font-size: 14px; border-radius: 4px; font-weight: 500; cursor: pointer; background-color: #888; color: #fff; margin-left: 0px; margin-right: 10px;">
        <input style="display:none;" type="file" @change="transformFile">上傳名單
      </label>
      <el-button type="primary" style="margin-right: 0 !important;" @click="setSend">送出</el-button>
    </div>
    <mu-dialog :open="progressBar" width="550">
      <mu-linear-progress></mu-linear-progress>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      service: '',
      auth: '',
      progressBar: false,
      sheetUsers: [],
      tableData: [
        { Phone: '', remark: '' }
      ],
      sheetData: [
        { 'Phone': '0981070946', 'remark': '哈哈哈' },
      ],
      services: ['FB', 'LINE'],
      group: []
    };
  },
  methods: {
    ...emc.mapActions({
      updateUserBeta: 'updateUserBeta'
    }),
    setSend() {
      const groupNum = parseInt(this.tableData.length / 200) + 1;
      for (let i = 0; i < groupNum; i += 1) {
        const index = i;
        this.group[i] = [];
      }
      for (let e = 0; e < this.tableData.length; e += 1) {
        const group = parseInt(e / 200);
        this.group[group].push(this.tableData[e]);
      }
      console.log(`總計 ${this.group.length} 組名單`);
      for (let x = 0; x < this.group.length; x += 1) {
        const totalMem = this.group[x];
        setTimeout(() => {
          this.updateUserBeta({ 
            service: this.service,
            auth: this.auth,
            updateList: totalMem
          })
          .then(() => {
            console.log(`已完成 ${(x + 1)}/${this.group.length} 組名單`)
          })
        }, (x * 8000))
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['Phone', 'remark'];  // 设置 Excel 的表格第一行的标题
        const filterVal = ['Phone', 'remark'];  // tableData 里对象的属性
        const list = this.sheetData;  //把 data 里的 tableData 存到 list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'sample');  //导出 Excel 文件名
      })
    },
    transformFile(e) {
      e.preventDefault();
      let file = e.target.files;
      var vm = this;
      vm.tableData = [];
      vm.progressBar = true;
      let fileReader = new FileReader();
      fileReader.onload = function(ev) {
        try {
          var data = ev.target.result,
          workbook = XLSX.read(data, { type: 'binary' }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
        } 
        catch (e) {
          return;
        }
        // 表格的表格范围，可用于判断表头是否数量是否正确
        var fromTo = '';
        // 遍历每张表读取
        for (var sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }
        vm.tableData = persons;
        vm.progressBar = false;
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file[0]);
    },
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
  }
</style>